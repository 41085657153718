import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { useAffiliatesContext } from '@/components/contexts/affiliates';
import { useStoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import { ErrorOverlay } from '@/components/shared/ErrorOverlay';
import { ReferralSchemeBanner } from '@/components/storyblok/bloks/banner-components/ReferralSchemeBanner';
import { RewardBanner } from '@/components/storyblok/bloks/banner-components/RewardBanner';
import { copy } from '@/copy';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import apiClient from '@/services/api-client';
import {
	CreateAffiliateSessionMutation,
	GetAffiliateLinkQuery,
} from '@/services/typed-graphql-sdk';
import { isBrowser } from '@/utils/browser/isBrowser';
import { SALES_CHANNELS_THAT_RECEIVE_D2C_REFERRAL_REWARD } from '@/utils/constants/marketing';
import { PAGES } from '@/utils/constants/pages';

type AffiliateBannerProps = {
	affiliateCode: string;
	// Optional audio recording id that is used for field sales registrations.
	audioRecordingId?: string;
};

const AffiliateBanner: FC<AffiliateBannerProps> = ({
	affiliateCode,
	audioRecordingId,
}) => {
	const router = useRouter();
	const storyblokBannersContext = useStoryblokBannersContext();
	const { isServersideError, affiliateContent, setIsAffiliatesReady } =
		useAffiliatesContext();

	const [affiliateLinkId, setAffiliateLinkId] = useState('');

	/**
	 * Session Storage Logic
	 */

	const [, setAffiliateOrganisationName] = useSessionStorage<
		string | undefined
	>('affiliateOrganisationName', '');
	const [affiliateSalesChannel, setAffiliateSalesChannel] = useSessionStorage<
		string | undefined
	>('affiliateSalesChannel', '');

	const [affiliateSessionCode, setAffiliateSessionCode] = useSessionStorage<
		string | undefined
	>('affiliateCode', '');

	const [, setAffiliateSessionId] = useSessionStorage<string | undefined>(
		'affiliateSessionId',
		''
	);

	const [, setAudioRecordingId] = useSessionStorage<string | undefined>(
		'audioRecordingId',
		''
	);

	const [referralSchemeCode, setReferralSchemeCode] = useSessionStorage<
		string | undefined
	>('referralSchemeCode', '');

	/**
	 * Error Logic
	 */

	const [showErrorOverlay, setShowErrorOverlay] = useState(false);

	const onDismissError = () => {
		delete router.query.affiliateCode;
		delete router.query.affiliate;

		router.push({ pathname: PAGES.homepage, query: {} }).then(() => {
			setShowErrorOverlay(false);
		});
	};

	const shouldDefaultToD2CBanner =
		SALES_CHANNELS_THAT_RECEIVE_D2C_REFERRAL_REWARD.some(
			(salesChannel) => salesChannel === affiliateSalesChannel
		);

	useEffect(() => {
		if (isServersideError || isError) {
			setShowErrorOverlay(true);
			setTimeout(onDismissError, 15000);
		}
	}, [isServersideError]);

	/**
	 * When clientside affiliate content needs to be called in the Email Followup journey
	 * This only applies to email follow up users
	 * */

	const shouldGetClientsideAffiliateLink = Boolean(
		!affiliateContent && router.query?.affiliate
	);

	const isThereNoExistingSessionYetForThisAffiliateLink = Boolean(
		affiliateCode && !affiliateSessionCode
	);

	const affiliateLinkQuery = useQuery({
		queryKey: ['affiliateLink', router.query?.affiliate],
		queryFn: async () => {
			return await apiClient.post<GetAffiliateLinkQuery>(
				'/api/onboarding/get-affiliate-link',
				{ subdomain: router.query?.affiliate || affiliateSessionCode }
			);
		},
		enabled:
			shouldGetClientsideAffiliateLink &&
			isThereNoExistingSessionYetForThisAffiliateLink,
		refetchOnWindowFocus: false,
	});

	const { data, isError } = affiliateLinkQuery;

	useEffect(() => {
		if (data?.affiliateLink) {
			const { activeAffiliateReferralScheme, affiliateLink } = data;
			const params = new URLSearchParams(window.location.search);
			/**
			 * Prefer the referralSchemeCode from URL params over the affiliate linked scheme.
			 * We use this for the win-back reward campaign.
			 * Example link: /join?affiliate=win-back-8888&referralSchemeCode=WIN-BACK-8888
			 */
			const referralSchemeCodeFromParamsOrContext =
				(params.get('referralSchemeCode') ||
					activeAffiliateReferralScheme?.code) ??
				'';
			setReferralSchemeCode(referralSchemeCodeFromParamsOrContext);
			setAffiliateLinkId(affiliateLink.id);
			setAffiliateSalesChannel(affiliateLink.organisation?.salesChannel ?? '');
			setAffiliateOrganisationName(affiliateLink.organisation?.name ?? '');
			audioRecordingId && setAudioRecordingId(audioRecordingId);
			setIsAffiliatesReady(true);
		}
	}, [data]);

	/**
	 *  When serverside affiliate content available
	 * */

	useEffect(() => {
		if (affiliateContent?.affiliateLink) {
			const { activeAffiliateReferralScheme, affiliateLink } = affiliateContent;
			const params = new URLSearchParams(window.location.search);
			/**
			 * Prefer the referralSchemeCode from URL params over the affiliate linked scheme.
			 * We use this for the win-back reward campaign.
			 * Example link: /join?affiliate=win-back-8888&referralSchemeCode=WIN-BACK-8888
			 */
			const referralSchemeCodeFromParamsOrContext =
				(params.get('referralSchemeCode') ||
					activeAffiliateReferralScheme?.code) ??
				'';
			setReferralSchemeCode(referralSchemeCodeFromParamsOrContext);
			setAffiliateSalesChannel(affiliateLink.organisation?.salesChannel ?? '');
			setAffiliateOrganisationName(affiliateLink.organisation?.name ?? '');
			setAffiliateLinkId(affiliateContent?.affiliateLink.id);
			audioRecordingId && setAudioRecordingId(audioRecordingId);
		}
	}, [affiliateContent?.affiliateLink]);

	/**
	 *  Will run regardless of serverside vs clientside
	 * */

	const createAffiliateSessionQuery = useQuery({
		queryKey: ['createAffiliateSession', affiliateLinkId],
		queryFn: async () => {
			const { ipAddress } = await apiClient.get<{ ipAddress: string }>(
				'/api/get-ip'
			);
			return await apiClient.post<CreateAffiliateSessionMutation>(
				'/api/onboarding/create-affiliate-session',
				{
					input: {
						linkId: affiliateLinkId,
						ipAddress,
						...(isBrowser() ? { userAgent: navigator.userAgent } : {}),
						...(audioRecordingId
							? {
									queryParams: JSON.stringify({
										audio_recording_id: audioRecordingId,
									}),
								}
							: {}),
					},
				}
			);
		},
		// Prevent double call on render
		staleTime: 100,
		enabled: Boolean(affiliateLinkId),
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (createAffiliateSessionQuery.data?.createAffiliateSession) {
			const {
				createAffiliateSession: { affiliateSession },
			} = createAffiliateSessionQuery.data;
			setAffiliateSessionCode(affiliateCode ?? affiliateSessionCode);
			setAffiliateSessionId(affiliateSession?.id);
		}
	}, [createAffiliateSessionQuery.data]);

	/**
	 * @note With serverside implementation, matching banners are now instantly able to be searched and displayed
	 * I've also run into a situation where story slugs were not in lower case or else referral scheme codes were not in lower case
	 * leading to banners not displaying. Not sure why this was but I've reconciled everything to lowercase for now.
	 * One example being Yohko's BtoB-beauty-2024-08-30000 https://app.storyblok.com/#/me/spaces/122730/stories/0/0/529697774
	 */
	const bannerStory =
		storyblokBannersContext.bannerStories.find(
			(story) => story.slug?.toLowerCase() === referralSchemeCode?.toLowerCase()
		) ??
		storyblokBannersContext.bannerStories.find(
			(story) => story.slug?.toLowerCase() === affiliateCode?.toLowerCase()
		);

	return (
		<>
			{showErrorOverlay && (
				<ErrorOverlay
					message={
						<>
							<strong>{copy.invalidUrl}</strong>
							<span className="block">{copy.invalidAffiliateLink}</span>
						</>
					}
					buttonText={copy.close}
					onClick={onDismissError}
				/>
			)}
			{affiliateContent !== null || affiliateSessionCode ? (
				bannerStory?.content ? (
					<RewardBanner
						data-testid="affiliate reward banner"
						aria-label="affiliate reward banner"
						content={bannerStory?.content}
					/>
				) : shouldDefaultToD2CBanner ? (
					/**
					 * The affiliate link does not have an associated banner story.
					 * In this case, display the current signup reward banner.
					 */ <ReferralSchemeBanner />
				) : null
			) : (
				/**
				 * Error state, display nothing. Error messages are handled by Toast or Sentry.
				 */
				<></>
			)}
		</>
	);
};

export { AffiliateBanner };
