import { FC } from 'react';

import { useDomesticRewardContext } from '@/components/contexts/domesticReward';
import { useStoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import { RewardBanner } from '@/components/storyblok/bloks/banner-components/RewardBanner';

export const ReferralSchemeBanner: FC<{ referralSchemeCode?: string }> = ({
	referralSchemeCode,
}) => {
	const domesticRewardContext = useDomesticRewardContext();

	const storyblokBannersContext = useStoryblokBannersContext();

	const referralSchemeCodeFromPropsOrContext =
		referralSchemeCode ||
		domesticRewardContext.activeDomesticSignupRewardSchemeCode;

	const bannerStory = storyblokBannersContext.bannerStories.find(
		(story) =>
			story?.slug?.toLowerCase() ===
			referralSchemeCodeFromPropsOrContext?.toLowerCase()
	);

	return bannerStory?.content ? (
		<RewardBanner
			data-testid="reward-banner"
			aria-label="reward banner"
			content={bannerStory?.content}
		/>
	) : null;
};
